.header {
  display: flex;
  align-items: center;
  max-width: 80%;
  height: 3.5rem;
  padding-bottom: 10px;
}

.header-iframe{
  display: flex;
  align-items: center;
  max-width: 80%;
  height: 3.1rem;
  padding-bottom: 10px;
}

.image-logo {
  max-width: 100%;
  min-width: 50%;
  display: flex;
}

.Cypress-logo{
  margin-top: 0.5%;
  margin-left: 4%;
  min-height: 1.5rem;
  max-height: 3.3rem;
  min-width: 30%;
  max-width: 60%;
  flex-shrink: 1;
  cursor: pointer;
}

.Cypress-logo-iframe{
  margin-top: 0.5%;
  margin-left: 4%;
  min-height: 1.5rem;
  max-height: 3.3rem;
  min-width: 30%;
  max-width: 60%;
  flex-shrink: 1;
}

.writergpt-logo{
  margin-left: 5%;
  min-height: 1.5rem;
  max-height: 3.2rem;
  min-width: 30%;
  max-width: 60%;
  flex-shrink: 1;
  cursor: pointer;
}

.writergpt-logo-iframe{
  margin-left: 5%;
  min-height: 1.5rem;
  max-height: 3.2rem;
  min-width: 30%;
  max-width: 60%;
  flex-shrink: 1;
}

.logo:hover {
  cursor: pointer;
}

.header-text {
  display: flex;
  max-width: 80%;
  margin-top: 24px;
  align-items: center;
}

.biographical {
  display: flex;
  min-width: 11rem;
  /* max-width: 11.375rem; */
  min-height: 2.6rem;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--Active-text, #2e7d32);
}
.biographical-section {
  display: flex;
  min-width: 11rem;
  /* max-width: 11.375rem; */
  min-height: 2.6rem;
  justify-content: center;
  align-items: center;
}

.text-bio {
  margin-bottom: none !important;
  color: #65a386;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.orbitary {
  display: flex;
  min-width: 8.6rem;
  min-height: 2.6rem;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--Active-text, #2e7d32);
}

.not-selected-orbitary,
.not-selected-biography {
  color: #3a3635;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.orbitary-section {
  display: flex;
  min-width: 8.6rem;
  /* max-width: 11.375rem; */
  min-height: 2.6rem;
  justify-content: center;
  align-items: center;
}
.text-orbitary {
  margin-bottom: none !important;
  color: #65a386;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.popup-content {
  border-radius: 1%;
  padding: 2%;
}

.popup-container {
  position: fixed;
  left: 30%;
  top: 40%;
  background-color: white;
  padding-bottom: 1%;
  z-index: 999;
  width: 35%;
  padding: 1%;
  border: 2px solid green;
}


.close-popup-container {
  position: fixed;
  left: 35%;
  top: 30%;
  background-color: white;
  padding-bottom: 1%;
  z-index: 999;
  width: 30%;
  padding: 1%;
  border: 2px solid green;
}

.popup-text {
  padding-top: 3%;
  padding-bottom: 0;
  margin-bottom: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: 600;
}

.close-button,
.close-button:hover {
  /* color: #198754; */
  color: #fff;
  font-weight: 500;
  /* border-radius: 0.5rem; */
  /* border: 2px solid #198754; */
  background-color: #2e7d32;
  margin: 1%;
  min-width: 130px;;
}

.okay-button, .okay-button:hover, .okay-button:active{
  background-color: rgb(230, 78, 78);
  min-width: 130px;
  max-width: fit-content;
  color: white;
}

.button-container {
  position: absolute;
  right: 20px; /* Adjust the right value as needed for proper alignment */
  top: 4.5%; /* Vertically center the button */
  transform: translateY(-50%);
}

.MuiTooltip-tooltip {
  color: #198754 !important;
  background-color: white !important;
  border: 2px solid #198754 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  width: 210px !important;
  text-align: center !important;
}

.btn-refresh {
  font-weight: 600;
}

.cross-button{
  position: absolute;
  top: 2%;
  right: 1.75%;
  z-index: 1000;
  height: 4%;
  margin: 0;
  display: flex;
  align-items: center;
}