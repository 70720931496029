.homepage-body {
  display: flex;
  max-width: 100%;
  min-height: 92vh;
  /* height: 100%; */
}

.obituary-body {
  background-color: #ecedf2;
  display: flex;
  min-width: 100%;
  min-height: 92vh;
  margin: auto;
  /* height: 100%; */
}

.left-container,
.right-container {
  flex: 1;
  width: 100%;
  border: 1px solid #ccc;
}

.left-form-container {
  flex: 1;
  max-width: 90%;
}

.right-text-container {
  max-width: 90%;
  flex: 1;
}
