.right-container {
  max-height: 92vh;
  /* max-height: 100%; */
  /* background-color: rebeccapurple; */
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 69, 69, 0.5); /* Adjust the opacity as needed */
  backdrop-filter: blur(1px); /* Apply the blur effect */
  z-index: 998;
}
