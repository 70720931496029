.box {
  background-color: white;
}

.back-button {
  width: 120px;
  color: #198754;
  font-weight: 500;
  height: 45px;
  border-radius: 0.5rem;
  margin-right: 26px;
  border: 2px solid #198754;
}

.back-button:hover {
  width: 120px;
  background-color: #f2eeee;
  font-weight: 500;
  color: #198754;
  height: 45px;
  border-radius: 0.5rem;
  margin-right: 26px;
  border: 2px solid #198754;
}
.btn-next,
.btn-next:hover {
  font-weight: 500;
  min-width: 120px;
  height: 45px;
  color: #fff;
  border-radius: 8px;
  background: var(--Active-text, #2e7d32);
}

.btn-generate,
.btn-generate:hover {
  font-weight: 500;
  min-width: 120px;
  height: 45px;
  color: #fff;
  border-radius: 8px;
  background: var(--Active-text, #2e7d32);
}
.bottom-container {
  display: flex;
  max-height: 8%;
  justify-content: end;
  margin-bottom: 1%;
}

.form-control:focus {
  border-color: #4ea684 !important;
  box-shadow: 0 0 0 0.25rem rgb(16 92 43 / 25%) !important;
}

.form-select:focus {
  border-color: #4ea684 !important;
  box-shadow: 0 0 0 0.25rem rgb(16 92 43 / 25%) !important;
}

.placeholder-text::placeholder {
  color: var(--neutral-light-gray, #92a0b3);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.timeOfCeremonyMessage{
  color: var(--neutral-light-gray, #92a0b3);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.custom-dropdown {
  background-color: white;
}

.Mui-active {
  color: #4c804f !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  word-wrap: break-word !important;
}

.Mui-completed {
  color: #aaaaaa !important;
  font-size: 16 !important;
  font-weight: 600 !important;
  word-wrap: break-word !important;
  text-transform: capitalize !important;
}

.MuiStepLabel-label {
  font-size: 16 !important;
  font-weight: 600 !important;
  word-wrap: break-word !important;
  text-transform: capitalize !important;
}

.MuiStepIcon-text {
  fill: white !important;
}

.outlet {
  height: 66vh;
  overflow-x: hidden;
}

.obituary-outlet {
  height: 57vh;
  overflow-x: hidden;
}

.Mui-completed {
  color: green;
}

.personalityTraitsContainer,
.hobbiesContainer,
.writingToneOptions {
  border: 1px solid silver;
  max-height: 160px;
  border-radius: 10px;
  overflow-x: auto;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  display: none;
}

.btn-personality,
.btn-personality:hover {
  border: 1px solid black;
}

.selected {
  background: #f57c00;
  color: white;
  font-weight: bold;
}

.recaptcha-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  background-color: white;
  /* border: 1px solid black; */
  border: 2px solid green;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 450px;
  min-height: 380px;
}

.btns {
  display: flex;
  width: 100%;
  justify-content: space-around;
  font-family: Arial, Helvetica, sans-serif;
}

.obituary-box {
  background-color: white;
  padding: 5%;
  padding-bottom: 3%;
}

.selected-trait,
.selected-hobby,
.selected-length {
  background-color: #f57c00;
  color: white;
  font-weight: bold;
}

.not-selected-trait,
.not-selected-hobby,
.not-selected-length {
  background-color: #fff;
}

.custom-field {
  line-height: 2;
}

.custom-email {
  line-height: 2.1;
  width: 304px;
  margin: auto;
}

.error {
  font-size: 14px;
  padding-left: 5px;
}

.middle-name::placeholder {
  font-size: 14.5px;
}

.writing-message {
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
}

.time-picker:focus {
  border-color: #4ea684 !important;
  box-shadow: 0 0 0 0.25rem rgb(16 92 43 / 25%) !important;
}

.checkbox-input {
  transform: scale(1.1);
  padding-top: o.1rem;
}

.MuiInputBase-input {
  height: 0.4rem !important;
}

.MuiFormControl-root:focus {
  border-color: #4ea684 !important;
  box-shadow: 0 0 0 0.25rem rgb(16 92 43 / 25%) !important;
}

.email-error {
  width: 85%;
}
