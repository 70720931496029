.left-container {
  display: flex;
  /* height: 93vh; */
  min-height: 100%;
}

.left-container {
  background-image: url("../../assets//bg1.png");
  background-position: top right;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-container:after {
  margin-top: 4%;
  vertical-align: middle;
  display: inline-block;
  content: url("../../assets//bg2.png");
  position: absolute;
  right: -30px;
  max-height: 90%;
  background-size: 100% 100%;
  transform: none;
  overflow: hidden;
  top: 0px;
}

.left-image {
  width: 100%;
  padding-right: 2%;
}

@media screen and (min-width: 950px) {
  .left-image {
    width: 500px;
    padding-right: 2%;
  }
}
