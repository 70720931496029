.bottom-buttons {
  display: flex;
  justify-content: space-around;
}

.edit-button,
.edit-button:hover {
  align-items: center;
  justify-content: center;
  min-width: 30%;
  max-width: 50%;
  background-color: #ffffff;
  border: 1px solid var(--Stoke_color, rgba(146, 160, 179, 0.6));
  font-weight: 500;
  max-height: 50px;
}

.copy-button,
.copy-button:hover,
.copy-button:active {
  align-items: center;
  justify-content: center;
  min-width: 25%;
  background-color: #2e7d32;
  border-right: 1px solid var(--Stoke_color, rgba(146, 160, 179, 0.6));
  color: white;
  font-weight: 500;
  max-height: 40px;
  max-width: auto;
  font-size: 16px;
}

.regenerate-button,
.regenerate-button:hover {
  align-items: center;
  justify-content: center;
  min-width: 20%;
  background-color: #2e7d32;
  border-right: 1px solid var(--Stoke_color, rgba(146, 160, 179, 0.6));
  color: white;
  font-weight: 500;
  max-height: 50px;
  max-width: 50%;
}

.obituary-container {
  margin: auto;
  padding-bottom: 0%;
  width: 80%;
  background-color: white;
  min-height: 80vh;
  overflow: auto;
  height: 86.5vh;
}

.obituary-container-iframe{
  margin: auto;
  padding-bottom: 0%;
  width: 80%;
  background-color: white;
  min-height: 80vh;
  overflow: auto;
}


.other-buttons, .other-buttons:active, .other-buttons:hover{
  align-items: center;
  justify-content: center;
  background-color: #2e7d32;
  font-size: 16px;
  border-right: 1px solid var(--Stoke_color, rgba(146, 160, 179, 0.6));
  color: white;
  font-weight: 500;
  max-height: 40px;
  max-width: auto;
}